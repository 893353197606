<template>
  <product title="Amazfit GTS"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :buy-link="links"
    is-bus-city1
    is-switch-price
    is-link-array
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr',
  components: {
    Product
  },
  data () {
    return {
      slogan: '超视网膜级 AMOLED 屏幕 | 自定义模块化表盘 | 屏幕全天候显示<br/>多彩轻薄金属表身 | 14 天长续航 | 50 米防水 | NFC 门禁公交<br/>12 种运动模式',
      colors: [
        '曜石黑',
        '沙漠金',
        '海豚灰',
        '糖果红',
        '冰川蓝',
        '蔷薇粉',
        '钛金属'
      ],
      price: [
        899,
        899,
        899,
        899,
        899,
        899,
        1299
      ],
      links: [
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/ECG1Kh',
        'https://j.youzan.com/KTCL09'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/gts/swiper/1.png',
          CDN_URL + '/images/product/gts/swiper/2.png',
          CDN_URL + '/images/product/gts/swiper/3.png',
          CDN_URL + '/images/product/gts/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/5.png',
          CDN_URL + '/images/product/gts/swiper/6.png',
          CDN_URL + '/images/product/gts/swiper/7.png',
          CDN_URL + '/images/product/gts/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/9.png',
          CDN_URL + '/images/product/gts/swiper/10.png',
          CDN_URL + '/images/product/gts/swiper/11.png',
          CDN_URL + '/images/product/gts/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/13.png',
          CDN_URL + '/images/product/gts/swiper/14.png',
          CDN_URL + '/images/product/gts/swiper/15.png',
          CDN_URL + '/images/product/gts/swiper/16.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/17.png',
          CDN_URL + '/images/product/gts/swiper/18.png',
          CDN_URL + '/images/product/gts/swiper/19.png',
          CDN_URL + '/images/product/gts/swiper/20.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/21.png',
          CDN_URL + '/images/product/gts/swiper/22.png',
          CDN_URL + '/images/product/gts/swiper/23.png',
          CDN_URL + '/images/product/gts/swiper/24.png'
        ],
        [
          CDN_URL + '/images/product/gts/swiper/25.png',
          CDN_URL + '/images/product/gts/swiper/26.png',
          CDN_URL + '/images/product/gts/swiper/27.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/gts/detail/s01.jpg',
        CDN_URL + '/images/product/gts/detail/s02.jpg',
        CDN_URL + '/images/product/gts/detail/s03.jpg',
        CDN_URL + '/images/product/gts/detail/s04.jpg',
        CDN_URL + '/images/product/gts/detail/s05.jpg',
        CDN_URL + '/images/product/gts/detail/s06.jpg',
        CDN_URL + '/images/product/gts/detail/s07.jpg',
        CDN_URL + '/images/product/gts/detail/s08.jpg',
        CDN_URL + '/images/product/gts/detail/s09.jpg',
        CDN_URL + '/images/product/gts/detail/s10_1.jpg',
        CDN_URL + '/images/product/gts/detail/s11.jpg',
        CDN_URL + '/images/product/gts/detail/s12.jpg',
        CDN_URL + '/images/product/gts/detail/s13.jpg',
        CDN_URL + '/images/common/zepp-white.jpg'

      ],
      paramImages: [
        CDN_URL + '/images/product/gts/detail/s14.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
